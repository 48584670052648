import { env } from "@/next.config";
import httpUtils from "@/utils/httpUtils";

export async function createStripeCheckout(config) {
    return httpUtils.post(`${env.API_VERSION}/create-checkout-session`, config);
}

export async function getUserCards(cus_id) {
    return httpUtils.get(`${env.API_VERSION}/user-payment-methods/${cus_id}`);
}

export async function getUserInvoices(cus_id) {
    return httpUtils.get(`${env.API_VERSION}/user-invoices/${cus_id}`);
}

export async function stopUserSubscription(sub_id) {
    return httpUtils.get(`${env.API_VERSION}/stop-user-subscription/${sub_id}`);
}

export async function resumeUserSubscription(sub_id) {
    return httpUtils.get(`${env.API_VERSION}/resume-user-subscription/${sub_id}`);
}

export async function updateUserSubscription(sub_id, payload) {
    return httpUtils.put(`${env.API_VERSION}/update-user-subscription/${sub_id}`, payload);
}

export async function attachUserPaymentMethod(pm_id, cus_id) {
    return httpUtils.get(`${env.API_VERSION}/attach-user-payment-method/${pm_id}/${cus_id}`);
}

export async function setDefaultUserPaymentMethod(pm_id, cus_id) {
    return httpUtils.get(`${env.API_VERSION}/update-default-user-payment-method/${pm_id}/${cus_id}`);
}

export async function getDefaultUserPaymentMethod(cus_id) {
    return httpUtils.get(`${env.API_VERSION}/get-default-user-payment-method/${cus_id}`);
}

export async function deleteUserPaymentMethod(pm_id) {
    return httpUtils.get(`${env.API_VERSION}/delete-user-payment-method/${pm_id}`);
}

export async function updateSession(sessionId, payload) {
    return httpUtils.put(`${env.API_VERSION}/stripe-sessions/${sessionId}`, payload);
}

export async function createPaymentIntent(payload) {
    return httpUtils.post(`${env.API_VERSION}/create-payment-intent`, payload);
}

export async function createSubscription(payload) {
    return httpUtils.post(`${env.API_VERSION}/create-subscription`, payload);
}

export async function startSubscriptionNow(payload) {
    return httpUtils.post(`${env.API_VERSION}/start-subscription-now`, payload);
}

export async function upgradeSubscription(payload) {
    return httpUtils.post(`${env.API_VERSION}/upgrade-subscription`, payload);
}

export async function getStripePrice(priceId) {
    return httpUtils.get(`${env.API_VERSION}/stripe-price/${priceId}`);
}

export async function createPaymentLink(payload) {
    return httpUtils.post(`${env.API_VERSION}/create-payment-link`, payload);
}

export async function getPaymentLinks(payload) {
    return httpUtils.get(`${env.API_VERSION}/payment-links`, payload);
}

export async function getNextBillingAmount(sub_id, params) {
    return httpUtils.get(`${env.API_VERSION}/next-billing-amount/${sub_id}`, params);
}

export async function applyCouponNextBilling(sub_id, payload) {
    return httpUtils.post(`${env.API_VERSION}/apply-coupon/${sub_id}`, payload);
}

export async function storeCancellationReason(payload) {
    return httpUtils.post(`${env.API_VERSION}/cancellation-reasons`, payload);
}

export async function checkStripeInvoice(invoice_id, params) {
    return httpUtils.get(`${env.API_VERSION}/invoice-status/${invoice_id}`, params);
}