import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import useAuth from "@/hooks/auth";
import productsDev from "@/hooks/products.dev";
import productsProd from "@/hooks/products.prod";
import { createSubscription, upgradeSubscription } from "@/services/api/Stripe";
import { checkCoupon } from "@/services/api/User";
import { Check, Warning } from "@phosphor-icons/react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Spinner from "../Spinner";
import { Checkbox } from "../ui/checkbox";
import { purchasePopup, hidePurchasePopup } from "@/Atoms";
import { useAtom } from "jotai";

// Load Stripe.js with your public key
const stripePromise = loadStripe(
  process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY
);

// Create a component for the form
const CheckoutForm = ({
  theme,
  priceData: _priceData,
  refreshUserSubscription,
  planType,
  currencyCode,
  planSubscription,
  startup,
  priceDataMonthly,
  priceIdsMonthly,
  nextAction,
}) => {
  const { user } = useAuth();
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [billingCycle, setBillingCycle] = useState("yearly");
  const [priceData, setPriceData] = useState(_priceData);
  const router = useRouter();
  const [coupon, setCoupon] = useState(null);
  const [coupons, setCoupons] = useState(null);
  const [inputCoupon, setInputCoupon] = useState("");
  const [couponError, setCouponError] = useState(false);
  const [couponErrorProduct, setCouponErrorProduct] = useState(false);
  const [loadingCheck, setloadingCheck] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [isCoupon, setIsCoupon] = useState(false);
  const [isHidePurchasePopup, setIsHidePurchasePopup] =
    useAtom(hidePurchasePopup);
  const currentPath = router.asPath;

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: theme === "light" ? "#000000" : "#ffffff",
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
    hidePostalCode: true,
  };

  const products =
    process.env.ENV !== "production" ? productsDev : productsProd;

  const handleCheckboxChange = () => {
    if (isCoupon) {
      setInputCoupon("");
      setCoupon(null);
    }
    setIsCoupon(!isCoupon);
  };

  useEffect(() => {
    if (billingCycle === "monthly") {
      setPriceData(priceDataMonthly);
    } else {
      setPriceData(_priceData);
    }
    console.log(_priceData);
    console.log(priceDataMonthly);
  }, [billingCycle]);

  const handleCheckCoupon = async (e) => {
    e.preventDefault();
    if (!inputCoupon) return;
    if (loadingCheck || coupon) return;
    setCouponError(false);
    setCouponErrorProduct(false);
    setCoupon(null);
    setCoupons(null);
    setloadingCheck(true);
    try {
      const res = await checkCoupon(inputCoupon);
      console.log(res);
      if (res.data.redeem_by && Date.now() > res.data.redeem_by * 1000) {
        setCouponError(true);
        setloadingCheck(false);
        return;
      }
      if (res.data.all && res.data.all.data) {
        setCoupons(res.data.all.data);
        const found = res.data.all.data.find((c) => c.id === res.data.id);
        if (found) {
          if (found.applies_to && found.applies_to.products) {
            if (
              found.applies_to.products.find(
                (product) => product === products[planType]
              )
            ) {
              setCoupon(res.data);
            } else {
              setCoupon(null);
              setCouponErrorProduct(true);
            }
          } else {
            setCoupon(res.data);
          }
        }
      } else {
        setCoupon(res.data);
      }
      setloadingCheck(false);
    } catch (error) {
      console.log(error);
      setCouponError(true);
      setloadingCheck(false);
      setCoupon(null);
      setCouponErrorProduct(false);
    }
  };

  const handleCouponChange = (e) => {
    setInputCoupon(e.target.value);
  };

  const onRemoveCoupon = (e) => {
    e.preventDefault();
    setInputCoupon("");
    setCoupon(null);
  };

  const handleSubmit = async (event) => {
    setIsLoadingSubmit(true);
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    console.log("cardElement", cardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name: user.email,
        email: user.email,
      },
    });

    console.log("paymentMethod", paymentMethod);

    if (error) {
      setIsLoadingSubmit(false);

      return toast.error(error.message, {
        position: "bottom-center",
      });
    }

    if (!error) {
      const { id } = paymentMethod;

      try {
        // const response = await createPaymentIntent({ payment_method_id: id });
        const response = await createSubscription({
          paymentMethod: id,
          name: user.name,
          email: user.email,
          priceId: priceData[planType].id,
          coupon_id: coupon ? coupon.id : null,
          planType,
        });
        console.log("response 1", response);
        if (response.data.clientSecret) {
          console.log(
            "Starting the payment confirmation process, which may include OTP authentication"
          );
          setIsHidePurchasePopup(true);
          console.log("response 2", response);
          const toastId = toast.custom(
            (t) => (
              <div
                className={
                  "max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5"
                }
              >
                <div className='flex-1 w-0 p-4 text-slate-600'>
                  <p>Payment processing, please wait...</p>
                  <p>{"Don't close your browser!"}</p>
                </div>
              </div>
            ),
            {
              duration: Infinity,
              id: "processing-payment", // Optionally, you can set a static ID if you want
            }
          );

          const paymentResult = await stripe.confirmCardPayment(
            response.data.clientSecret,
            {
              payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                  name: user.name,
                  email: user.email,
                },
              },
            }
          );

          console.log("paymentResult", paymentResult);

          // setIsLoadingSubmit(false);

          if (paymentResult.error) {
            console.log("Payment failed:", paymentResult.error);
            toast.dismiss(toastId); // Dismiss the 'Payment processing' toast
            toast.error(`Payment failed: ${paymentResult.error}`)
            // location.reload();
          } else {
            if (paymentResult.paymentIntent.status === "succeeded") {
              setTimeout(async () => {
                localStorage.setItem("payment", planType);
                localStorage.setItem("previousPagePayment", currentPath);
                // refreshUserSubscription();
                // location.href = router.query?.redirect_to || "/account/plan";
                if (nextAction) {
                  nextAction();
                } else {
                  location.reload();
                }
              }, 2000);
            }
          }
        } else {
          // setIsLoadingSubmit(false);
          setTimeout(() => {
            localStorage.setItem("payment", planType);
            localStorage.setItem("previousPagePayment", currentPath);
            // refreshUserSubscription();
            // location.href = router.query?.redirect_to || "/account/plan";
            location.reload();
          }, 2000);
        }
      } catch (error) {
        console.log(error);
        setIsLoadingSubmit(false);
      }
    }
  };

  const handleUpgrade = async (event) => {
    setIsLoadingSubmit(true);
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name: user.email,
        email: user.email,
      },
    });

    if (error) {
      setIsLoadingSubmit(false);

      return toast.error(error.message, {
        position: "bottom-center",
      });
    }

    if (!error) {
      const { id } = paymentMethod;

      const response = await upgradeSubscription({
        payment_method: id,
        name: user.name,
        email: user.email,
        price_id: priceData[planType].id,
        subscription_id: planSubscription.subscription_detail.id,
        customer_id: planSubscription.subscription_detail.customer,
        coupon_id: coupon ? coupon.id : null,
        planType
      });

      if (response.data.latest_invoice.payment_intent) {
        const { status, client_secret } =
          response.data.latest_invoice.payment_intent;

        if (status === "requires_action") {
          // The payment needs to be confirmed
          const { error, paymentIntent } = await stripe.confirmCardPayment(
            client_secret
          );

          if (error) {
            console.log("Error confirming the payment", error);
            // Handle error...
          } else {
            console.log("Payment confirmed successfully");
            if (paymentIntent.status === "succeeded") {
              setTimeout(() => {
                localStorage.setItem("payment", planType);
                localStorage.setItem("previousPagePayment", currentPath);
                refreshUserSubscription();
                // location.href = router.query?.redirect_to || "/account/plan";
              }, 2000);
            }
            // Handle successful confirmation...
          }
        } else if (status === "succeeded") {
          console.log("Payment succeeded");
          setTimeout(() => {
            localStorage.setItem("payment", planType);
            localStorage.setItem("previousPagePayment", currentPath);
            refreshUserSubscription();
            // location.href = router.query?.redirect_to || "/account/plan";
          }, 2000);
          // Handle successful payment...
        }
      }
    }
  };

  return (
    <>
      {planType === "Startup Registration" && (
        <p className='mb-6 text-sm font-semibold'>
          <span className='text-orange-500'>7 days trial for </span>
          <span className='text-orange-400'>Startup Registration</span>
        </p>
      )}
      <form
        onSubmit={
          planSubscription &&
          planSubscription.has_active_subscription &&
          planSubscription.is_content_plan
            ? (e) => handleUpgrade(e)
            : (e) => handleSubmit(e)
        }
        className={`divide-y lg:-my-4 ${
          theme === "light" ? "divide-background/10" : "divide-white/10"
        }`}
      >
        <div className='flex items-center justify-between py-4'>
          <p className='text-sm font-medium'>Price</p>
          <div className='text-right'>
            <p
              className={`text-xl font-semibold tracking-tight lg:text-2xl ${
                theme === "light" ? "text-background" : "text-white"
              }`}
            >
              {currencyCode === "USD" ? "$" : currencyCode + " "}
              {priceData
                ? (priceData[planType].unit_amount / 100).toLocaleString()
                : ""}
            </p>
          </div>
        </div>
        {planType === "Content Access" && (
          <div className='flex items-center justify-between py-4'>
            <p className='text-sm font-medium'>Billing Cycle</p>
            <div className='flex items-center gap-x-1'>
              <Button
                type='button'
                onClick={() => setBillingCycle("yearly")}
                className={`border text-xs h-auto py-1.5 px-3 gap-x-1.5 ${
                  theme === "light"
                    ? `text-background border-background/10 ${
                        billingCycle === "yearly"
                          ? "bg-background text-white hover:bg-background/80"
                          : "bg-background/5 hover:bg-background/10 text-background"
                      }`
                    : `text-white border-white/10 ${
                        billingCycle === "yearly"
                          ? "bg-white text-background"
                          : "bg-white/5 hover:bg-white/10"
                      }`
                }`}
              >
                <span
                  className={`w-3.5 h-3.5 border inline-flex justify-center items-center rounded-full ${
                    theme === "light"
                      ? `text-background border-background/10 ${
                          billingCycle === "yearly"
                            ? "bg-green-500 text-white border-green-500"
                            : "bg-background/5 border-background/5"
                        }`
                      : `text-white border-white/10 ${
                          billingCycle === "yearly"
                            ? "bg-green-500 text-white border-green-500"
                            : "bg-white/5 border-white/5"
                        }`
                  }`}
                >
                  {billingCycle === "yearly" && (
                    <Check weight='bold' className='text-[9px]' />
                  )}
                </span>
                Yearly (20% OFF)
              </Button>
              <Button
                type='button'
                onClick={() => setBillingCycle("monthly")}
                className={`border text-xs h-auto py-1.5 px-3 gap-x-1.5 ${
                  theme === "light"
                    ? `text-background border-background/10 ${
                        billingCycle === "monthly"
                          ? "bg-background text-white hover:bg-background/80"
                          : "bg-background/5 hover:bg-background/10 text-background"
                      }`
                    : `text-white border-white/10 ${
                        billingCycle === "monthly"
                          ? "bg-white text-background"
                          : "bg-background/5 hover:bg-background/10"
                      }`
                }`}
              >
                <span
                  className={`w-3.5 h-3.5 border inline-flex justify-center items-center rounded-full ${
                    theme === "light"
                      ? `text-background border-background/10 ${
                          billingCycle === "monthly"
                            ? "bg-green-500 text-white border-green-500"
                            : "bg-background/5 border-background/5"
                        }`
                      : `text-white border-white/10 ${
                          billingCycle === "monthly"
                            ? "bg-green-500 text-white border-green-500"
                            : "bg-white/5 border-white/5"
                        }`
                  }`}
                >
                  {billingCycle === "monthly" && (
                    <Check weight='bold' className='text-[9px]' />
                  )}
                </span>
                Monthly
              </Button>
            </div>
          </div>
        )}

        <div className='flex items-center justify-between gap-10 py-4'>
          <p className='text-sm font-medium'>Card Info</p>
          <div className='flex-1'>
            <CardElement options={CARD_ELEMENT_OPTIONS} />
          </div>
        </div>
        {/* <button type='submit' disabled={!stripe}> */}
        {/* {!(planSubscription && planSubscription.has_active_subscription) && ( */}
        <div className='pt-5'>
          <label className='flex items-center space-x-2 cursor-pointer'>
            <Checkbox
              onCheckedChange={handleCheckboxChange}
              className={`${
                theme === "light" ? "border-background/10" : "border-white/10"
              }`}
            />
            <span className='text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'>
              I have a coupon code
            </span>
          </label>
          {isCoupon && (
            <div className='flex items-center justify-between pt-5'>
              <p className='text-sm font-medium'>Coupon Code (Optional)</p>
              <div>
                <div className='flex gap-2'>
                  <Input
                    onChange={handleCouponChange}
                    className='bg-white/5 hover:bg-white/10'
                    disabled={coupon}
                    value={inputCoupon}
                    onBlur={() => setCouponError(false)}
                  />
                  <Button
                    type='button'
                    onClick={coupon ? onRemoveCoupon : handleCheckCoupon}
                    className={`${
                      coupon
                        ? "bg-red-500/20 hover:bg-red-500/30 text-red-400"
                        : `${
                            theme === "light"
                              ? "bg-background/70 text-white hover:bg-background/50"
                              : "bg-white text-background"
                          }`
                    }`}
                  >
                    {loadingCheck ? "Checking..." : coupon ? "Remove" : "Apply"}
                  </Button>
                </div>
                {coupon && (
                  <p className='flex items-center font-medium mt-1.5 text-xs text-green-500 gap-x-1.5'>
                    <Check weight='bold' /> Coupon Applied
                  </p>
                )}
                {couponErrorProduct && (
                  <p className='flex items-center font-medium mt-1.5 text-xs text-red-500 gap-x-1.5'>
                    <Warning weight='bold' /> This coupon is not valid for this
                    subscription.
                  </p>
                )}
                {couponError && (
                  <p className='flex items-center font-medium mt-1.5 text-xs text-red-500 gap-x-1.5'>
                    <Warning weight='bold' /> This Coupon does not exist or has
                    expired.
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
        {/* )} */}

        {coupon && (
          <div className='pt-4'>
            <div className='flex justify-between'>
              <p className='text-sm font-medium'>
                Coupon <span className='text-sm'>({coupon.id})</span>
              </p>
              <p>
                -{currencyCode !== "USD" ? currencyCode + " " : "$"}
                {coupon.percent_off
                  ? Math.round(
                      (coupon.percent_off / 10000) *
                        priceData[planType].unit_amount
                    ).toLocaleString("en", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : (coupon.amount_off / 100).toLocaleString("en", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
              </p>
            </div>
            <div className='flex justify-between mt-3'>
              <p className='text-sm font-medium'>Total</p>
              <p className='text-xl font-bold tracking-tight text-transparent lg:text-2xl bg-clip-text bg-gradient-to-br from-yellow-1 to-red-1'>
                {currencyCode !== "USD" ? currencyCode + " " : "$"}
                {coupon.percent_off
                  ? (
                      priceData[planType].unit_amount / 100 -
                      Math.round(
                        (coupon.percent_off / 10000) *
                          priceData[planType].unit_amount
                      )
                    ).toLocaleString("en", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : (
                      priceData[planType].unit_amount / 100 -
                      coupon.amount_off / 100
                    ).toLocaleString("en", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
              </p>
            </div>
          </div>
        )}
        {/* {isCoupon && planType === 'Startup Registration' && (
          <div className='text-sm border-none mt-3 -mb-3 flex items-center gap-x-1'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              stroke-width='1.5'
              stroke='currentColor'
              className='w-5 h-5 text-gray-500'
            >
              <path
                stroke-linecap='round'
                stroke-linejoin='round'
                d='M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z'
              />
            </svg>
            <p className="text-gray-500">Trial is not valid when you use a coupon</p>
          </div>
        )} */}
        <Button
          className={`w-full text-white text-base h-auto py-2.5 mt-6 ${
            (isLoadingSubmit ? "animate-pulse opacity-90" : "",
            `${
              theme === "light"
                ? "bg-background hover:bg-background/80"
                : "bg-gradient-to-tr from-yellow-1 to-red-1"
            }`)
          }`}
          type='submit'
          disabled={!stripe || isLoadingSubmit}
        >
          {isLoadingSubmit && <Spinner color='slate-800' />}
          <span>{isLoadingSubmit ? "Processing..." : "Proceed to Pay"}</span>
        </Button>
      </form>
    </>
  );
};

const CardForm = ({
  theme,
  planSubscription,
  userLocation,
  priceData,
  priceIds,
  currencyCode,
  ip,
  startup,
  refreshUserSubscription,
  planType,
  priceDataMonthly,
  priceIdsMonthly,
  nextAction,
}) => {
  const router = useRouter();

  if (
    planSubscription &&
    planSubscription.has_active_subscription &&
    planSubscription.is_content_plan
  ) {
    if (planType === "Content Access") {
      // router.push('/purchase?type=startup')
      return null;
    }
  }

  if (
    planSubscription &&
    planSubscription.has_active_subscription &&
    planSubscription.is_startup_plan
  ) {
    return <div>Your plan: Startup Registration</div>;
  }

  return (
    <div>
      {/* {planSubscription ? ( */}
      <CheckoutForm
        theme={theme}
        refreshUserSubscription={refreshUserSubscription}
        planType={planType}
        priceData={priceData}
        currencyCode={currencyCode}
        planSubscription={planSubscription}
        startup={startup}
        priceDataMonthly={priceDataMonthly}
        priceIdsMonthly={priceIdsMonthly}
        nextAction={nextAction}
      />
      {/* ) : (
        <p>Loading payment...</p>
      )} */}
    </div>
  );
};

export default CardForm;
